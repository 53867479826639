import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import { SubmitButton } from '../_components';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                firstName: '',
                surname: '',
                email: '',
                cellPhone: '',
                homePhone: '',
                street: '',
                suburb: '',
                city: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('registerForm');
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        form.classList.add('was-validated')

        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (user.firstName && user.surname && user.email && user.cellPhone) {
            dispatch(userActions.register(user));
        }
    }

    render() {
        const { registering  } = this.props;
        const { user } = this.state;
        return (
            <div className="col-md-6 col-md-offset-3">
                <h2 className="text-center">Register</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="registerForm" className="needs-validation my-4" noValidate onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} required onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your first name
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="surname">Family Name</label>
                                    <input type="text" className="form-control" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your family name
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" name="email" value={user.email} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your email
                                    </div>
                                    <small id="cellPhoneHelp" className="form-text text-muted tiny">Will be used as your username and generated password will be send to this address</small>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cellPhone">Mobile</label>
                                    <input type="text" className="form-control" name="cellPhone" value={user.cellPhone} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your mobile
                                    </div>
                                    <small id="cellPhoneHelp" className="form-text text-muted tiny">We use it to only contact you re your bookings</small>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="securityQuestion">Security Question</label>
                                    <input type="text" className="form-control" name="securityQuestion" required value={user.securityQuestion} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter the security question
                                    </div>
                                    <small id="securityQuestionHelp" className="form-text text-muted tiny">We use it to verify it's you when you try to reset your password</small>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="securityAnswer">Security Answer</label>
                                    <input type="text" className="form-control" name="securityAnswer" required value={user.securityAnswer} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter the answer to your security question
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <span>Address</span>
                                <small id="addressHelp" className="form-text text-muted tiny">We use it when sending purchased vouchers</small>
                            </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="street">Street</label>
                                    <input type="text" className="form-control" name="street" value={user.street} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="suburb">Suburb</label>
                                    <input type="text" className="form-control" name="suburb" value={user.suburb} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="city">City</label>
                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <SubmitButton updating={registering} label="Register" labelLoading="Registering ..."/>
                                    <Link to="/login" className="btn btn-link">Cancel</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.user;
    return {
        registering
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
