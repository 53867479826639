import React from 'react';
import { Link } from 'react-router-dom';
import  ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';

import { alertActions, userActions } from '../../_actions';
import { SubmitButton } from '../../_components';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        console.log('constructor: ' + props);

        this.state = {
            username: '',
            gRecaptchaResponse: '',
            securityAnswer: '',
            step: 1,
            dirty: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitSecurityAnswer = this.handleSubmitSecurityAnswer.bind(this);
        this.handleGetSecurityQuestion = this.handleGetSecurityQuestion.bind(this);
        this.previousStep = this.previousStep.bind(this);
    }

    previousStep(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({
          step : 1
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.setState({ dirty: true });
    }

    handleRecaptcha(e) {
        this.setState({ gRecaptchaResponse: e });
    }

    handleSubmitSecurityAnswer(e) {
        e.preventDefault();
        var form = document.getElementById('formStep2');
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        form.classList.add('was-validated')

        const { username, securityAnswer } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(userActions.resetPassword(username, securityAnswer));
    }

    handleGetSecurityQuestion(e) {
        e.preventDefault();
        var form = document.getElementById('formStep1');
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        form.classList.add('was-validated')

        const { username, gRecaptchaResponse, dirty } = this.state;
        const { dispatch, securityQuestion } = this.props;
        dispatch(alertActions.clear());
        if ((!securityQuestion && username && gRecaptchaResponse) ||
            (securityQuestion && username && gRecaptchaResponse && dirty)) {
            this.setState({ dirty: false });
            this.setState({ step: 2 });
            dispatch(userActions.securityQuestion(username, gRecaptchaResponse));
        } else if (securityQuestion && username) {
            this.setState({ step: 2 });
        } else if (!gRecaptchaResponse) {
            dispatch(alertActions.error("Finish the ReCaptcha"));
        }
    }

    render() {
        const { requestingQuestion, requestingQuestionError, resettingPassword, securityQuestion } = this.props;
        const { username, gRecaptchaResponse, securityAnswer, dirty } = this.state;
        console.log("Step before: " + this.state.step);
        console.log("dirty before: " + dirty);
        if (requestingQuestionError && this.state.step !== 1) {
          this.setState({
            step : 1,
            dirty : true
          })
        }
        console.log("Step: " + this.state.step);
        console.log("dirty: " + dirty);
        console.log("requestingQuestionError: " + requestingQuestionError);
        console.log("securityQuestion: " + securityQuestion);
        console.log("requestingQuestion: " + requestingQuestion);
        console.log("resettingPassword: " + resettingPassword);
        return (
            <div className="col-12 col-sm-10 col-sm-offset-1 col-md-5 col-md-offset-6 col-lg-3 col-lg-offset-5">
                <h2 className="text-center">Reset your password</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                    {(this.state.step === 1 || requestingQuestion) &&
                        <form id="formStep1" className="needs-validation my-4" noValidate onSubmit={this.handleGetSecurityQuestion}>
                            <div className="form-group">
                                <label htmlFor="username">Your email</label>
                                <input type="email" className="form-control" required name="username" value={username} onChange={this.handleChange} />
                                <div className="invalid-feedback">
                                    Please enter your email
                                </div>
                                <small id="emailHelp" className="form-text text-muted">Enter the email you used when you registered</small>
                            </div>
                            {(!securityQuestion || dirty || !gRecaptchaResponse) &&
                                <div className='form-group'>
                                    <label>ReCaptcha</label>
                                    <ReCAPTCHA ref="recaptcha" sitekey="6LeeZgoTAAAAAKSVkjaL2Egi9mHyIdgGTPIEPKQ8" onChange={this.handleRecaptcha.bind(this)}/>
                                </div>
                            }
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <SubmitButton updating={requestingQuestion} label="Get the security question" labelLoading="Getting the question ..."/>
                                    <Link to="/login" className="btn btn-link">Cancel</Link>
                                </div>
                            </div>
                            <script src="https://www.google.com/recaptcha/api.js" async="async" defer="defer"/>
                        </form>
                    }
                    {((this.state.step === 2 && !requestingQuestion) || resettingPassword) &&
                        <form id="formStep2" className="needs-validation my-4" noValidate onSubmit={this.handleSubmitSecurityAnswer}>
                            <div className='form-group'>
                                <label>Security question</label>
                                <div className="font-weight-bold">{securityQuestion}</div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="securityAnswer">Security answer</label>
                                <input type="text" className="form-control" name="securityAnswer" required value={securityAnswer} onChange={this.handleChange} />
                                <div className="invalid-feedback">
                                  Please enter the answer to your security question
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col mb-3">
                                    <SubmitButton updating={resettingPassword} label="Reset password" labelLoading="Reseting password ..." className="btn-block"/>
                                </div>
                                <div className="col mb-3">
                                    <button type="button" className="btn btn-secondary" onClick={this.previousStep}>Back</button>
                                    <Link to="/login" className="btn btn-link">Cancel</Link>
                                </div>
                            </div>
                        </form>
                    }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { requestingQuestion, requestingQuestionError, securityQuestion, resettingPassword } = state.user;
    return {
        requestingQuestion, requestingQuestionError, securityQuestion, resettingPassword
    };
}

const connectedResetPasswordPage = connect(mapStateToProps)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };
